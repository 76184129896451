var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DefaultDialog",
        { on: { close: _vm.close } },
        [
          !_vm.isLoading
            ? _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))])
            : _vm._e(),
          _c(
            "template",
            { slot: "content" },
            [
              _vm.isLoading
                ? _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("LoaderCard", {
                        attrs: { flat: "", type: "spinner--center" }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isLoading
                ? [
                    !_vm.isFailed && !_vm.isSucceeded
                      ? [
                          _c(
                            "v-flex",
                            { staticClass: "tw-pt-0", attrs: { xs12: "" } },
                            [
                              _c("v-text-field", {
                                staticClass: "tw-mb-16 tw--mt-8",
                                attrs: {
                                  label: "Specificatienummer",
                                  placeholder:
                                    "vul hier een specificatienummer in",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.form.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "name", $$v)
                                  },
                                  expression: "form.name"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-layout",
                                {
                                  staticClass: "fileUpload__container",
                                  attrs: {
                                    "fill-height": "",
                                    "pa-1": "",
                                    wrap: ""
                                  }
                                },
                                [
                                  _c("MiFileUpload", {
                                    ref: "fileUpload",
                                    attrs: {
                                      filePath: _vm.uploadedFile,
                                      clearAfterUpload: false,
                                      multiple: false,
                                      acceptedFileTypes: _vm.acceptedFileTypes,
                                      fileIcon: "insert_drive_file"
                                    },
                                    on: {
                                      itemDropped: function($event) {
                                        return _vm.handleItemDropped($event)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      : _vm._e(),
                    _vm.isSucceeded
                      ? [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "text-center": "" } },
                            [
                              _c("span", [
                                _vm._v(
                                  "Het uploaden van het bestand is gelukt."
                                )
                              ])
                            ]
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "text-center": "" } },
                            [
                              _c(
                                "div",
                                { staticClass: "big-info-icon" },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "success" } },
                                    [_vm._v("check_circle_outline")]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      : _vm._e(),
                    _vm.isFailed
                      ? [
                          _vm.errors &&
                          _vm.errors.message &&
                          _vm.errors.message.errors
                            ? _c(
                                "v-layout",
                                {
                                  staticClass:
                                    "item__list item__list--overflow",
                                  attrs: { wrap: "" }
                                },
                                [
                                  _vm._l(_vm.errors.message.errors, function(
                                    errors,
                                    errorsIndex
                                  ) {
                                    return [
                                      _vm._l(errors, function(error, index) {
                                        return [
                                          _c(
                                            "v-flex",
                                            {
                                              key:
                                                "mismatch_level-" +
                                                index +
                                                "-" +
                                                errorsIndex,
                                              staticClass: "list__item",
                                              attrs: { xs12: "" }
                                            },
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: { "align-center": "" }
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { shrink: "" } },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color:
                                                              error.level ==
                                                              "error"
                                                                ? "warning"
                                                                : "alert"
                                                          }
                                                        },
                                                        [_vm._v("warning")]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { shrink: "" } },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(error.message)
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      })
                                    ]
                                  })
                                ],
                                2
                              )
                            : _vm._e()
                        ]
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          ),
          _c(
            "template",
            { slot: "actions" },
            [
              !_vm.isLoading
                ? _c(
                    "v-layout",
                    { attrs: { "justify-end": "", "align-center": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          _vm.isFailed || _vm.isSucceeded
                            ? _c(
                                "span",
                                {
                                  staticClass: "fadedText link",
                                  on: { click: _vm.close }
                                },
                                [_vm._v("Sluiten")]
                              )
                            : _vm._e(),
                          !_vm.isFailed && !_vm.isSucceeded
                            ? [
                                _c(
                                  "span",
                                  {
                                    staticClass: "fadedText link mr-3",
                                    on: { click: _vm.close }
                                  },
                                  [_vm._v("Annuleren")]
                                ),
                                _c(
                                  "MiButton",
                                  {
                                    attrs: {
                                      disabled: _vm.isDisabled,
                                      color: "primary",
                                      small: "true"
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.create($event)
                                      }
                                    }
                                  },
                                  [_vm._v("Indienen")]
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }