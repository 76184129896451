import { Organization } from '@/models/Organization';
import { CostSpecification, Status } from '@/models/CostSpecification';
import { currency, formatDate, hours } from '@/support/String';
import { Component, Vue, Prop } from 'vue-property-decorator';
import DataTable, { TableOptions, TableVisibility } from '@/components/data-table/DataTable';
import CreateSpecificationDialog from '@/views/Invoice/CreateSpecificationDialog/CreateSpecificationDialog.vue';
import { User } from '@/models/User';

@Component<SpecificationsDatatable>({
  components: {
    CreateSpecificationDialog,
  },
})
export default class SpecificationsDatatable extends Vue {
// #region @Props
/**
* ie.
* @Prop()
* protected user!: User
*/
@Prop()
protected organization!: string;

@Prop()
protected status!: Status;
// #endregion

// #region @Refs
/**
* ie.
* @Ref()
* readonly anotherComponent!: AnotherComponent
*/
// #endregion

// #region Class properties
/**
* ie.
* protected isLoading = true;
* * protected company: Company | null = null;
*/

protected isLoading = false;

protected readOnly = false;

protected isCreatingSpecification = false;

protected specification: any | null = null;

protected tableFilters: SpecificationsTableFilter = {};

protected visibility: TableVisibility = {
  checkboxes: false,
  total: true,
  title: false,
  search: false,
};
// #endregion

// #region Lifecycle Hooks / Init
/**
* ie.
* protected mounted(): void {
*  console.log('MyComponent has Mounted');
* }
*/

public async mounted() {

}

// #endregion

// #region Class methods
/**
* ie.
* protected toggleLoading(): void {
*  this.isLoading = ! this.isLoading;
* }
*/

protected refreshDatatable() {
  if (this.$refs.specificationsDatatable) {
    (this.$refs.specificationsDatatable as DataTable).refresh();
  }
}
// #endregion

// #region Async methods
/**
* ie.
* protected async fetchUserCompany(): Promise<void> {
*  this.company = await new Company().filter({user: this.user.id}).all();
* }
*/
// #endregion

// #region Getters & Setters
/**
* ie.
* protected get companyName(): string {
*  return this?.company.name || 'N/A';
* }
*/
protected get tableOptions(): TableOptions {
  return {
    model: new CostSpecification()
      .include([
        'organization',
        'uploaded_by_user',
        'submitted_by_user',
        'status_updated_by',
      ])
      .filter({
        ...this.dataTableFilters,
        status: this.status,
      }),
    name: {
      singular: 'Deskundigenregister aanvragen',
      plural: 'Deskundigenregister aanvragen',
    },
    headers: [
      {
        text: 'Specificatienummer',
        value: 'specification_number',
        action: '/specificaties/{id}',
        width: '15%',
      },
      {
        text: 'Bureau',
        value: 'organization',
        width: '10%',
        transform: (organization: Organization) => (organization ? organization.name : ''),
        visible: this.$store.state.isServiceOrganization,
      },
      ...! this.$store.state.isServiceOrganization
        ? [
          {
            text: 'Geupload op',
            value: 'created_at',
            transform: (created_at: string) => (created_at ? formatDate(created_at, 'dd-LL-yyyy HH:mm') : ''),
          },
          {
            text: 'Geupload door',
            value: 'uploaded_by_user',
            transform: (uploaded_by_user: User) => (uploaded_by_user ? uploaded_by_user.name : ''),
          },
        ]
        : [],
      ...! this.isUploaded
        ? [
          {
            text: 'Ingediend op',
            value: 'submitted_at',
            transform: (submitted_at: string) => (submitted_at ? formatDate(submitted_at, 'dd-LL-yyyy HH:mm') : ''),
          },
          {
            text: 'Ingediend door',
            value: 'submitted_by_user',
            transform: (submitted_by_user: User) => (submitted_by_user ? submitted_by_user.name : ''),
          },
        ]
        : [],
      {
        text: 'Afgekeurd op',
        value: 'status_updated_at',
        transform: (status_updated_at: string) => (status_updated_at ? formatDate(status_updated_at, 'dd-LL-yyyy HH:mm') : ''),
        visible: () => this.isRejected,
      },
      {
        text: 'Afgekeurd door',
        value: 'status_updated_by',
        transform: (status_updated_by: User) => (status_updated_by ? status_updated_by.name : ''),
        visible: () => this.isRejected,
      },
      {
        text: 'Goedgekeurd op',
        value: 'status_updated_at',
        transform: (status_updated_at: string) => (status_updated_at ? formatDate(status_updated_at, 'dd-LL-yyyy HH:mm') : ''),
        visible: () => this.isAccepted,
      },
      {
        text: 'Goedgekeurd door',
        value: 'status_updated_by',
        transform: (status_updated_by: User) => (status_updated_by ? status_updated_by.name : ''),
        visible: () => this.isAccepted,
      },
      {
        text: '# Uurregels',
        value: 'hours_count',
        class: 'text-right',
      },
      {
        text: 'Som uren',
        value: 'hours_sum',
        class: 'text-right',
        transform: (hours_sum: number) => (hours_sum ? hours(hours_sum) : ''),
      },
      {
        text: 'Som kosten',
        value: 'cost_total',
        class: 'text-right',
        transform: (cost_total: number) => (cost_total ? currency(cost_total, 'EUR') : ''),
        visible: () => this.$store.state.Auth.is_rockefeller,
      },
    ],
    actions: [],
    filters: [],
  };
}

protected get dataTableFilters() {
  return this.tableFilters;
}

protected get specificationsDatatable(): DataTable | null {
  const dataTable = this.$refs.specificationsDatatable;
  if (dataTable) {
    return (dataTable as DataTable);
  }

  return null;
}

protected get isUploaded(): boolean {
  return this.status === 'uploaded';
}

protected get isPending(): boolean {
  return this.status === 'pending';
}

protected get isRejected(): boolean {
  return this.status === 'rejected';
}

protected get isAccepted(): boolean {
  return this.status === 'accepted';
}

  // #endregion

  // #region @Watchers
  /**
* ie.
* @Watch('company')
* protected companyChanged() {
*  console.log('Company Changed') ;
* }
*/
  // #endregion
}

interface SpecificationsTableFilter {
  organizations?: string[];
}
