var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isLoading
        ? _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _vm.isUploaded && !_vm.$store.state.isServiceOrganization
                ? _c(
                    "v-flex",
                    { attrs: { xs12: "", "text-right": "" } },
                    [
                      _c(
                        "MiButton",
                        {
                          attrs: {
                            color: "primary",
                            icon: "publish",
                            small: "true"
                          },
                          nativeOn: {
                            click: function($event) {
                              _vm.isCreatingSpecification = true
                            }
                          }
                        },
                        [_vm._v(" Uploaden ")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _vm.$store.state.isServiceOrganization
                        ? _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-layout",
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs3: "" } },
                                    [
                                      _c("ReportOrganizationFilter", {
                                        attrs: { cssClass: "with-border" },
                                        model: {
                                          value: _vm.tableFilters.organizations,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tableFilters,
                                              "organizations",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "tableFilters.organizations"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "pa-0": "" } },
                        [
                          _c("DataTable", {
                            ref: "specificationsDatatable",
                            attrs: {
                              options: _vm.tableOptions,
                              visibility: _vm.visibility
                            },
                            on: { "data-update": _vm.refreshDatatable }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isCreatingSpecification
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "600px", persistent: true },
              model: {
                value: _vm.isCreatingSpecification,
                callback: function($$v) {
                  _vm.isCreatingSpecification = $$v
                },
                expression: "isCreatingSpecification"
              }
            },
            [
              _c("CreateSpecificationDialog", {
                attrs: { specification: _vm.specification },
                on: { eventUpdated: _vm.refreshDatatable },
                model: {
                  value: _vm.isCreatingSpecification,
                  callback: function($$v) {
                    _vm.isCreatingSpecification = $$v
                  },
                  expression: "isCreatingSpecification"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }