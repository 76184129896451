import { Component, Vue } from 'vue-property-decorator';
import MiFileUpload from '@/components/mi-file-upload/MiFileUpload';
import { AxiosError } from 'axios';
import { CostSpecification } from '@/models/CostSpecification';
import { Error } from '../Specifications/Check/Check';

@Component<CreateSpecificationDialog>({})
export default class CreateSpecificationDialog extends Vue {
  // #region @Props
  // #endregion

  // #region @Refs
  // #endregion

  // #region Class properties
  protected isLoading = false;

  protected isFailed = false;

  protected isSucceeded = false;

  protected errors: Error = {};

  // Form
  protected form: {[key: string]: null | File | string} = {
    file: null,
    name: '',
  };

  // file uploading
  protected isUploaded = false;

  protected uploadedFile = '';

  protected acceptedFileTypes: string[] = [
    'application/vnd.ms-excel',
    'application/vnd.ms-excel',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
    'application/vnd.ms-excel.template.macroEnabled.12',
    'application/vnd.ms-excel.addin.macroEnabled.12',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  ];
  // #endregion

  // #region Lifecycle Hooks / Init
  // #endregion

  // #region Class methods

  protected close() {
    this.$emit('input', false);
    this.$emit('close');
  }

  protected handleItemDropped(file: File) {
    if (! file) {
      return;
    }

    this.form.file = file;
    const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;
    fileUploadComponent.uploaded();
  }
  // #endregion

  // #region Async methods
  /**
  * ie.
  * protected async fetchUserCompany(): Promise<void> {
  *  this.company = await new Company().filter({user: this.user.id}).all();
  * }
  */

  protected async create() {
    const payload = {
      file: this.form.file,
      specification_number: this.form.name,
    };

    this.isLoading = true;

    const response = await new CostSpecification()
      .create(payload)
      .catch((error: AxiosError) => {
        this.errors = error.response?.data.errors || {};
        this.isFailed = true;
      });

    if (response) {
      this.isSucceeded = true;
      this.$emit('eventUpdated');
    }

    this.isLoading = false;
  }
  // #endregion

  // #region Getters & Setters

  protected get title() {
    if (this.isFailed) {
      return 'Niet gelukt...';
    }

    if (this.isSucceeded) {
      return 'Gelukt!';
    }

    return 'Factuur specificatie indienen';
  }

  protected get isDisabled() {
    return ! this.form.file || ! this.form.name;
  }
  // #endregion

  // #region @Watchers
  // #endregion
}
